import { Block } from "@components/Block";
import { Contact } from "@components/Contact";
import { Container } from "@components/Container";
import { ContentBuilder } from "@components/ContentBuilder";
import { Hero } from "@components/Hero";
import { Layout } from "@components/Layout";
import { SectionSeparator } from "@components/SectionSeparator";
import { HeroCard, TeaserGrid } from "@components/Teasers";
import { APITypes } from "@lib/api.types";
import API from "lib/fetchData";
import { SupportedLocales } from "lib/localization";
import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

interface HomePageProps {
  pageContent: APITypes.Pages.Home;
}

const Home = ({ pageContent }: HomePageProps) => {
  const { t } = useTranslation("common");

  return (
    <Layout translate={t} pageTitle={t("pages.home")}>
      <Hero hero={pageContent.Hero} alignHero={"center"} />
      <Container>
        <Block>
          <div id="content" />
          <TeaserGrid gap="sm" columns={7}>
            {pageContent.HeroCard.map((attributes, i) => (
              <HeroCard
                key={i}
                text={attributes.text}
                backgroundColor={attributes.background_color}
                width={attributes.width}
                logo={attributes.logo?.data}
              />
            ))}
          </TeaserGrid>
          <div className="h-10 md:h-20"></div>
        </Block>
        <ContentBuilder contentBlocks={pageContent.page_content_blocks} />
        <SectionSeparator />
        <Block>
          <Contact contact={pageContent.Contact} />
        </Block>
      </Container>
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps<Partial<HomePageProps>> = async ({
  locale,
  defaultLocale,
}) => {
  const localeToFetch = (locale ?? defaultLocale) as SupportedLocales;
  const api = new API(localeToFetch);

  const { data: page } = await api.fetchHomePage();

  if (!page) return { notFound: true };
  return {
    props: {
      ...(await serverSideTranslations(localeToFetch, ["common"])),
      pageContent: page.attributes,
    },
  };
};
